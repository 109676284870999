<template>
    <a-layout class="yj-conten-div">
        <a-layout-content>
            <div class="yj-conten" style="background:#fff;padding:20px 20px 0;min-height:calc(100% - 48px)">
                <a-row class="yj-form">
                    <a-col :span="24">
                        <label>类别 :</label>
                        <a-radio-group v-model="category" @change="radioChange">
                            <a-radio-button value="-1">全部</a-radio-button>
                            <a-radio-button value="0">拉新</a-radio-button>
                            <a-radio-button value="1">意向</a-radio-button>
                            <a-radio-button value="2">咨询</a-radio-button>
                        </a-radio-group>
                    </a-col>
                    <a-col :span="24">
                        <!-- <label>介绍人 :</label>
                        <a-input placeholder="介绍人" style="width: 150px;margin: 0 8px 8px 0;" v-model="IntroducerName" />
                        <label>分享人 :</label>
                        <a-input placeholder="分享人" style="width: 150px;margin: 0 8px 8px 0;" v-model="SharerName"/> -->
                        <label>加入时间 :</label>
                        <a-range-picker style="width:260px;"
                                        :value="Time"
                                        @change="handleChange" />
                        <a-button type="primary" @click="getTableData">查询</a-button>
                    </a-col>
                </a-row>
                <a-table bordered :columns="columns"
                         rowKey="ID"
                         :data-source="tableData"
                         :pagination="pagination"
                         @change="pagerChange"
                         class="yj-table-img">
                    <span slot="imgs" slot-scope="text,record">
                        <img class="imgPreview" :src="record.UserInfo.UHeadImg" />
                    </span>
                    <span slot="name_slot" slot-scope="text,record">
                        <a @click="jump(record)">{{record.UserInfo.Name}}</a>
                    </span>
                    <span slot="Introducer" slot-scope="text,record">
                        <a @click="PartnerJump(record)">{{record.IntroducerInfo.Name}}</a>
                    </span>
                    <span slot="Sharer" slot-scope="text,record">
                        <a @click="PartnerSharer(record)">{{record.SharerID==null?'':record.SharerInfo.Name}}</a>
                    </span>
                </a-table>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import moment from 'moment';
    export default {
        name: "Operations_AgentGuestLogList",
        data() {
            return {
                category: "-1",
                Time: [],
                BeginTime: "",
                EndTime: "",
                IntroducerName:"",
                SharerName: "",
                 UserID:"",
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                columns: [
                    {
                        title: "头像",
                        align: "center",
                        scopedSlots: { customRender: "imgs" },
                        width: 100
                    },
                    {
                        title: "姓名",
                        width: 200,
                        scopedSlots: { customRender: "name_slot" },
                    },
                    {
                        title: "电话",
                        width: 150,
                        customRender: (text, item) => {
                            var rt = "";
                            if (item.UserInfo.Phone.length == 11) {
                                rt = item.UserInfo.Phone;
                            }
                            return rt;
                        }
                    },
                    {
                        title: "介绍人",
                        width: 150,
                        scopedSlots: { customRender: "Introducer" }
                    },
                    {
                        title: "分享人",
                        width: 150,
                        scopedSlots: { customRender: "Sharer" }
                    },
                    {
                        title: "状态",
                        width: 150,
                        customRender: (text, item) => {
                            var rt = "";
                            switch (item.TargetGuestCategory) {
                                case 0:
                                    rt = "拉新";
                                    break;
                                case 1:
                                    rt = "意向";
                                    break;
                                case 2:
                                    rt = "咨询";
                                    break;
                            }
                            return rt;
                        }
                    },
                    {
                        title: "备注",
                        width: 250,
                        dataIndex: "Summary",
                    },
                    {
                        title: "加入时间",
                        width: 250,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss');
                        }
                    }
                ],
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            radioChange: function (item) {
                var self = this;
                self.category = item.target.value;
                self.getTableData();
            },
            handleChange(value) {
                var self = this;
                self.Time = value;
                if (value.length == 0) {
                    self.BeginTime = "";
                    self.EndTime = "";
                } else {
                    self.BeginTime = util.formatDate(self.Time[0]._d);
                    self.EndTime = util.formatDate(self.Time[1]._d);
                }
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations/GetUAgentGuestLogList",
                    data: {
                        pageIndex: self.pagination.current,
                        TargetGuestCategory: self.category,
                        BeginDate: self.BeginTime,
                        EndDate: self.EndTime,
                        IntroducerName:self.IntroducerName,
                        SharerName: self.SharerName,

                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            jump: function (data) {
                var self = this;
                self.$router.push({
                    name: "OperationsDetail", query: {
                        ID: data.UserInfo.UserID
                    }
                })
            },
            PartnerJump: function (data) {
                var self = this;
                self.$router.push({
                    name: "PartnerList_PartnerDetail", query: {
                        ID: data.IntroducerInfo.UserID
                    }
                })
            },
             PartnerSharer: function (data) {
                 var self = this;
                 self.$router.push({
                     name: "OperationsDetail", query: {
                         ID: data.SharerInfo.UserID
                     }
                 })
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
            var self = this;
            var Category = this.$route.query.Category;
            if (!util.isBlank(Category)) {
                self.category = Category.toString();
            } else {
                self.category = "-1"
            }
            if (!util.isBlank(this.$route.query.BeginDate) && !util.isBlank(this.$route.query.EndDate)) {
                self.BeginTime = this.$route.query.BeginDate;
                self.EndTime = this.$route.query.EndDate;
                this.Time = [moment(self.BeginTime, 'YYYY-MM-DD'), moment(self.EndTime, 'YYYY-MM-DD')];
            }
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<style scoped>
    .imgPreview {
        border-radius: 4px;
        width: 50px;
        height: 50px;
    }
</style>